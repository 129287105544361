<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" style="color: azure">
            <h1>VELA Chatbot</h1>
            <h4>Prompt Support-Anywhere, Anytime!</h4>
        </div>
    </div>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title" style="color: #13c4a1"
                        >Personal Learning Assistant For Learners</span
                    >
                    <h2>
                        Online learning can be very easy with an AI assistant
                        like VELA
                    </h2>
                    <p>
                        Learn without technical limits with the VELA Chatbot,
                        your dedicated AI companion! Its 24/7 availability and
                        accurate responses ensure you have the support you need,
                        anytime, anywhere. Embrace the power of AI to enhance
                        your learning experience - start using VELA Chatbot
                        today!
                    </p>
                </div>
                <a
                    routerLink="/contact"
                    class="default-btn"
                    style="
                        background-color: #13c4a1;
                        color: #ffffff;
                        margin-top: 2rem;
                    "
                >
                    <i
                        _ngcontent-ilu-c84=""
                        class="bx bxs-hot"
                        style="color: #ffffff"
                    ></i
                    >Schedule Demo<span></span
                ></a>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/chatbot.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70" style="background-color: #13c4a1">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-conversation"></i>
                    </div>
                    <h3>24/7 Availability</h3>
                    <p>
                        VELA Chatbot is always on standby, available
                        round-the-clock to assist learners anytime, anywhere,
                        reducing wait times and ensuring continuous support,
                        even outside traditional working hours.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-conversation"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-customize"></i>
                    </div>
                    <h3>Seamless Integration</h3>
                    <p>
                        VELA Chatbot seamlessly integrates with existing
                        learning management systems and online platforms,
                        ensuring a smooth and cohesive support experience for
                        both educators and learners.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-customize"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-slider-alt"></i>
                    </div>
                    <h3>Custom Data Training</h3>
                    <p>
                        VELA Chatbot's capabilities are boosted by custom data
                        training, allowing it to be specifically tailored to an
                        institution's online learning platform and courses,
                        resulting in precise and context-aware responses.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-slider-alt"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-chart"></i>
                    </div>
                    <h3>Analytics and Insights</h3>
                    <p>
                        VELA Chatbot provides valuable analytics and insights
                        into learner interactions, enabling institutions to
                        identify trends, measure effectiveness, and continually
                        improve the learning support ecosystem.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-chart"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-cog"></i>
                    </div>
                    <h3>AI-Powered Automation</h3>
                    <p>
                        With AI, VELA Chatbot can automatically handle a wide
                        range of learner queries, ensuring swift and accurate
                        answers without the need for manual intervention.
                    </p>
                    <br />

                    <div class="back-icon">
                        <i class="bx bx-cog"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-shape-circle"></i>
                    </div>
                    <h3>Personalized Support</h3>
                    <p>
                        VELA Chatbot offers individualized assistance,
                        understanding each learner's unique needs and providing
                        tailored responses for a truly personalized learning
                        experience.
                    </p>
                    <br />

                    <div class="back-icon">
                        <i class="bx bx-shape-circle"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Testimonials Area -->
<!-- <section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Spacle</span></h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img
                            src="assets/img/author-image/author6.jpg"
                            alt="image"
                        />
                        <h3>Michel John</h3>
                        <span>CEO at Envato</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                        </p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img
                            src="assets/img/author-image/author7.jpg"
                            alt="image"
                        />
                        <h3>Sarah Taylor</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                        </p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img
                            src="assets/img/author-image/author8.jpg"
                            alt="image"
                        />
                        <h3>James Andy</h3>
                        <span>CEO at 3S</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                        </p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Testimonials Area -->

<app-free-trial></app-free-trial>
