<!-- Start Main area with video-->
<div class="banner-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="content">
                        <div class="">
                            <div class="inner-content plr-15">
                                <h1>
                                    GPT-Powered Learner Support For Successful
                                    Online Learning Programs
                                </h1>
                                <p>
                                    VELA's GPT-powered intelligence can be
                                    easily customised for your chosen online
                                    learning platform and offered courses.
                                    Experience automated assistance through chat
                                    and email support for your learners'
                                    queries, even beyond regular hours.
                                </p>

                                <ul class="banner-btn vela-home-btns">
                                    <li>
                                        <a
                                            routerLink="/contact"
                                            class="default-btn"
                                        >
                                            <i class="bx bxs-hot"></i>Schedule
                                            My Demo<span></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.youtube.com/watch?v=QGtKIOYj9uw"
                                            class="video-btn popup-youtube"
                                        >
                                            <i class="bx bxs-right-arrow"></i>
                                            Know More
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-img banner-video">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="video-box">
                                <video muted autoplay loop id="myVideo">
                                    <source
                                        src="assets/video/home-page.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End main area with video -->

<!-- product cards-->
<div class="features-card-section pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div
                class="col-lg-4 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
                (click)="redirect('/vela-for-institute')"
                style="cursor: pointer"
            >
                <div class="single-features-card blt-radius-0">
                    <i class="bx bx-building blt-radius-0"></i>
                    <h3>VELA For Institutes</h3>
                    <p>
                        Ensure improved productivity and success with custom AI
                        trained on your knowledge base.
                    </p>
                </div>
            </div>

            <div
                class="col-lg-4 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
                (click)="redirect('/vela-for-teachers')"
                style="cursor: pointer"
            >
                <div class="single-features-card blt-radius-0">
                    <i class="bx bx-user blt-radius-0"></i>
                    <h3>VELA For Teachers</h3>
                    <p>
                        AI assistance for easy round-the-clock management of
                        student queries.
                    </p>
                    <br />
                </div>
            </div>

            <div
                class="col-lg-4 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".4s"
                (click)="redirect('/vela-for-learners')"
                style="cursor: pointer"
            >
                <div class="single-features-card blt-radius-0">
                    <i class="bx bx-group blt-radius-0"></i>
                    <h3>VELA For Learners</h3>
                    <p>
                        Access to 24/7 support and resources for a smooth online
                        learning experience.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End product cards -->

<!-- Start About Area -->
<div class="about-area mt-5">
    <div class="container"></div>
    <div class="container-fluid">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-inner-image">
                        <img src="assets/img/vela-benefits.png" alt="image" />
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-inner-content">
                        <div class="content">
                            <h2>
                                Discover The Power Of AI Trained On Custom Data
                                For Online Learning!
                            </h2>
                            <ul class="features-list">
                                <li>
                                    <i class="bx bx-check"></i> Accurate and
                                    prompt assistance for learners
                                </li>

                                <li>
                                    <i class="bx bx-check"></i> Reduced cost
                                    overheads on IT support
                                </li>
                                <li>
                                    <i class="bx bx-check"></i> Reclaimed time
                                    for impactful teaching
                                </li>
                                <li>
                                    <i class="bx bx-check"></i> Optimised
                                    support processes and insights
                                </li>
                                <li>
                                    <i class="bx bx-check"></i> Seamless
                                    knowledge base integration
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Start SM Pricing Area -->
<div class="sm-pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="sm-pricing-section-content">
                    <span>Platforms Served</span>
                    <h3>
                        VELA supports various online learning platforms
                        seamlessly
                    </h3>
                    <p>
                        VELA's intelligence is currently pre-trained on the
                        knowledge base of <b>Moodle</b> and <b>Coursera</b>.
                        Institutes, learners and teachers using these platforms
                        can instantly use VELA for assistance.
                    </p>
                    <p>
                        VELA can also be customised for specific courses and
                        LMS. It can cater to the growing need for personalised
                        and on-demand support required by learners and teachers
                        using these online learning platforms.
                    </p>
                    <a routerLink="/contact" class="default-btn"
                        >Customize VELA<span></span
                    ></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="sm-pricing-card with-black-color">
                            <div class="pricing-header">
                                <h3>VELA For Moodle</h3>
                                <p>
                                    Learners, teachers and institutions using
                                    Moodle can leverage the extensive knowledge
                                    base of VELA to navigate the platform and
                                    get their queries answered.
                                </p>
                                <ul class="pricing-features">
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Accessibility
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Account setup
                                    </li>

                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Grades and Assignment
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Communication
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Course setup
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Certificate
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="sm-pricing-card with-black-color">
                            <div class="pricing-header">
                                <h3>VELA for Coursera</h3>
                                <p>
                                    Learners, teachers and institutions using
                                    Coursera can leverage the extensive
                                    knowledge base of VELA to navigate the
                                    platform and get their queries answered.
                                </p>
                                <ul class="pricing-features">
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Accounts and Notifications
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Payments and Subscriptions
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Enrollment
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Grades and Assignment
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Certification and Verification
                                    </li>
                                    <li>
                                        <i class="bx bx-check-circle"></i>
                                        Coursera Policies
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SM Pricing Area -->

<!-- Start SM Overview Area -->
<div class="sm-overview-area pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="sm-overview-card">
                    <div class="image-icon">
                        <img
                            src="https://media.giphy.com/media/UOdoMz3baCENO/giphy.gif"
                            alt="image"
                        />
                    </div>
                    <h3>10+ Countries Reached</h3>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="sm-overview-card">
                    <div class="image-icon">
                        <img
                            src="https://media.giphy.com/media/bTrTnPMPq8UORCrBWG/giphy.gif"
                            alt="image"
                        />
                    </div>
                    <h3>17+ Indian States</h3>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="sm-overview-card">
                    <div class="image-icon">
                        <img
                            src="https://media.giphy.com/media/l0JM83bF1jbRsTnNu/giphy.gif"
                            alt="image"
                        />
                    </div>
                    <h3>5000+ Interactions</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SM Overview Area -->

<!-- Start IS Case Studies Area - FAQs -->
<div class="is-case-studies-area ptb-100">
    <div class="container">
        <div
            class="section-title-with-large-box d-flex align-items-center justify-content-between"
        >
            <div class="max-width">
                <span>FAQs</span>
                <h2>Know More About VELA</h2>
            </div>
        </div>
        <div class="is-case-studies-list-tabs">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="1-tab"
                                data-bs-toggle="tab"
                                href="#one"
                                role="tab"
                                aria-controls="1"
                            >
                                <div class="number">1</div>
                                What can VELA do?
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="2-tab"
                                data-bs-toggle="tab"
                                href="#two"
                                role="tab"
                                aria-controls="2"
                            >
                                <div class="number">2</div>
                                Why do I need VELA?
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="3-tab"
                                data-bs-toggle="tab"
                                href="#three"
                                role="tab"
                                aria-controls="3"
                            >
                                <div class="number">3</div>
                                Where can I use VELA?
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="4-tab"
                                data-bs-toggle="tab"
                                href="#four"
                                role="tab"
                                aria-controls="4"
                            >
                                <div class="number">4</div>
                                How can VELA assist my Teachers?
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="5-tab"
                                data-bs-toggle="tab"
                                href="#five"
                                role="tab"
                                aria-controls="5"
                            >
                                <div class="number">5</div>
                                How can VELA assist my Learners?
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="tab-content" id="myTabContent">
                        <div
                            class="tab-pane fade show active"
                            id="one"
                            role="tabpanel"
                        >
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies1.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        VELA is a GPT-powered AI technology that
                                        can be customised according to the
                                        Institute's needs, and will streamline
                                        daily administrative tasks as well as
                                        manage and organise multiple learner and
                                        teacher queries instantly.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="two" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies2.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        With the changing educational landscape,
                                        the work within academic institutions
                                        and organisations is changing rapidly.
                                        Both teachers as well as learners
                                        require a lot of support to navigate the
                                        digital space. VELA will give your
                                        teachers and learners the support they
                                        need to make the best decisions, suited
                                        to the needs of the institution.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="three" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/vela-home-where-vel.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        VELA's AI technology equipped with the
                                        intelligence of current ed-tech
                                        platforms is perfect to cater to the
                                        needs of teachers and learners! While
                                        learners can benefit from instant
                                        resolution of their queries teachers can
                                        get immediate assistance in providing
                                        support to the learners.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="four" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/vela-home-where-vela.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        VELA's custom intelligence helps
                                        teachers confidently manage large number
                                        of learner queries accurately. This way
                                        teachers can instantly provide support
                                        to learners whenever they need it. After
                                        all, empowered teachers result in
                                        empowered students!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="five" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies5.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Addressing learners' queries promptly
                                        and accurately has been a priority of
                                        VELA's learners-first approach. With
                                        VELA's personal assistance, every
                                        learner can now experience round the
                                        clock support instantly and have an
                                        effortless online learning experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Case Studies Area -->

<!-- Start Blog Area -->
<section class="blog-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Blog And Insights</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a
                            target="_blank"
                            href="https://blog.npbridge.com/blended-learning-are-we-looking-at-the-future-of-education/"
                            ><img
                                src="assets/img/blog-image/blog_blended_learning.jpeg"
                                alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <h3>
                            <a
                                target="_blank"
                                href="https://blog.npbridge.com/blended-learning-are-we-looking-at-the-future-of-education/"
                                >Blended Learning: Are we looking at the future
                                of education?</a
                            >
                        </h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img
                                    src="assets/img/author-image/raya.jpeg"
                                    alt="image"
                                />
                                <h6>Raya Das</h6>
                            </div>
                            <div class="details-btn">
                                <a
                                    target="_blank"
                                    href="https://blog.npbridge.com/blended-learning-are-we-looking-at-the-future-of-education/"
                                    ><i class="bx bx-right-arrow-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a
                            target="_blank"
                            href="https://blog.npbridge.com/the-impact-of-the-covid-19-pandemic-on-education/"
                            ><img
                                src="assets/img/blog-image/blog_covid.jpeg"
                                alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <h3>
                            <a
                                target="_blank"
                                href="https://blog.npbridge.com/the-impact-of-the-covid-19-pandemic-on-education/"
                                >The impact of the COVID-19 pandemic on
                                education system</a
                            >
                        </h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img
                                    src="assets/img/author-image/raya.jpeg"
                                    alt="image"
                                />
                                <h6>Raya Das</h6>
                            </div>
                            <div class="details-btn">
                                <a
                                    target="_blank"
                                    href="https://blog.npbridge.com/the-impact-of-the-covid-19-pandemic-on-education/"
                                    ><i class="bx bx-right-arrow-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a
                            target="_blank"
                            href="https://blog.npbridge.com/how-did-the-evolution-of-online-learning-change-education/"
                            ><img
                                src="assets/img/blog-image/blog_history.jpeg"
                                alt="image"
                        /></a>
                    </div>

                    <div class="post-content">
                        <h3>
                            <a
                                target="_blank"
                                href="https://blog.npbridge.com/how-did-the-evolution-of-online-learning-change-education/"
                                >How did the evolution of online learning change
                                education?</a
                            >
                        </h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img
                                    src="assets/img/author-image/raya.jpeg"
                                    alt="image"
                                />
                                <h6>Raya Das</h6>
                            </div>
                            <div class="details-btn">
                                <a
                                    target="_blank"
                                    href="https://blog.npbridge.com/how-did-the-evolution-of-online-learning-change-education/"
                                    ><i class="bx bx-right-arrow-alt"></i
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>Explore the intersection of technology and social good</h2>

            <form class="newsletter-form" id="contactForm" #form="ngForm">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input
                            type="email"
                            class="input-newsletter"
                            placeholder="Your Email"
                            name="EMAIL"
                            [(ngModel)]="userEmail"
                        />
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <button type="submit" (click)="send(form)">
                            <i class="bx bxs-hot"></i> Subscribe Now
                        </button>
                    </div>
                </div>
                <div class="contact-info-content">
                    <h4
                        style="color: #13c4a1; padding-top: 2rem"
                        [innerHTML]="emailResponse"
                    ></h4>
                    <h4
                        style="
                            font-size: 0.875em;
                            padding-top: 2rem;
                            color: #dc3545;
                        "
                        [innerHTML]="errorMessage"
                    ></h4>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<app-free-trial></app-free-trial>
