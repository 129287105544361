import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import axios from "axios";
import { environment } from "src/environments/environment.prod";
import { Router } from "@angular/router";

@Component({
    selector: "app-home",
    templateUrl: "./velahome.component.html",
    styleUrls: ["./velahome.component.scss"],
})
export class VelaHomeComponent implements OnInit {
    token: string | undefined;

    constructor(private router: Router) {
        this.token = undefined;
    }

    public send(form: NgForm): void {
        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }
            return;
        }
        this.submitForm();
    }

    redirect(url: string) {
        this.router.navigateByUrl(url);
    }

    userEmail: string = "";
    errorMessage = "";
    emailResponse = "";

    validations() {
        var validMessage = {
            status: true,
            message: "",
        };
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.userEmail.match(mailformat)) {
            validMessage.status = false;
            validMessage.message = "Please enter a valid email address.";
            return validMessage;
        }
        return validMessage;
    }

    submitForm() {
        this.errorMessage = "";
        this.emailResponse = "";
        const validated = this.validations();
        if (validated.status) {
            const postData = {
                sendToEmail: environment.sendToEmail,
                userEmail: this.userEmail,
                subject: `SUBSCRIBE FORM- ${this.userEmail}`,
                message: "User wants to subscribe! Yay!!",
            };
            axios
                .post(`${environment.backendApi}/contact-submit`, postData)
                .then((res) => {
                    this.userEmail = "";
                    if ((res.status = 200))
                        this.emailResponse = "Subscribed successfully!";
                    else
                        this.errorMessage =
                            "Error in subscribing right now. Please send an email on contact@velabot.com";
                })
                .catch((err) => {
                    this.errorMessage =
                        "Error in subscribing right now. Please send an email on contact@velabot.com";
                });
        } else {
            this.errorMessage = validated.message;
        }
    }

    ngOnInit() {}
}
