<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="spacle-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/vela_logo_60x60.png" alt="logo" /> VELA
                </a>

                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li
                            class="nav-item"
                            data-toggle="collapse"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                        >
                            <a
                                routerLink="/"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                class="nav-link"
                                >Home</a
                            >
                        </li>

                        <li class="nav-item dropdown">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                            >
                                Explore <i class="bx bx-chevron-down"></i>
                            </a>

                            <ul class="dropdown-menu">
                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/vela-for-institute"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >VELA For Institutes</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/vela-for-teachers"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >VELA For Teachers</a
                                    >
                                </li>
                                <li
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/vela-for-learners"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >VELA For Learners</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li
                            class="nav-item"
                            data-toggle="collapse"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                        >
                            <a
                                routerLink="/about"
                                routerLinkActive="active"
                                class="nav-link"
                                >About Us</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                        >
                            <a
                                routerLink="/contact"
                                routerLinkActive="active"
                                class="nav-link"
                                >Contact</a
                            >
                        </li>
                    </ul>

                    <div class="others-options">
                        <a routerLink="/contact" class="default-btn">
                            <i class="bx bxs-hot"></i>Schedule a Demo<span
                            ></span>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
