<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list" style="height: 500px">
                        <div
                            *ngIf="apiLoaded | async"
                            style="height: 80%; width: 100%"
                        >
                            <google-map
                                [center]="center"
                                [zoom]="zoom"
                                width="100%"
                                height="100%"
                            >
                                <div
                                    style="
                                        position: relative;
                                        top: -9rem;
                                        left: 0px;
                                        width: 50%;
                                        height: auto;
                                    "
                                >
                                    <div
                                        style="
                                            background-color: white;
                                            margin: 10px;
                                            padding: 1px;
                                            box-shadow: rgba(0, 0, 0, 0.3) 0px
                                                1px 4px -1px;
                                            border-radius: 2px;
                                            padding: 1rem;
                                        "
                                    >
                                        <div jstcache="0">
                                            <div
                                                jstcache="51"
                                                class="place-card place-card-large"
                                            >
                                                <div class="place-desc-large">
                                                    <div
                                                        jstcache="24"
                                                        class="place-name"
                                                        jsan="7.place-name"
                                                        style="
                                                            font-size: 15px;
                                                            font-weight: 600;
                                                        "
                                                    >
                                                        NPBridge Solutions
                                                    </div>
                                                    <div
                                                        jstcache="25"
                                                        class="address"
                                                        jsan="7.address"
                                                        style="
                                                            font-size: 11px;
                                                            font-weight: 300;
                                                        "
                                                    >
                                                        4th Floor, Kiran Arcade
                                                        651, 13th Cross, 27th
                                                        Main Rd, 1st Sector, HSR
                                                        Layout, Bengaluru,
                                                        Karnataka 560102
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <map-marker
                                    [options]="markerOptions"
                                    (mapClick)="openMaps()"
                                ></map-marker>
                            </google-map>
                            <!-- <google-map [options]="options"></google-map> -->
                        </div>
                        <div class="container" style="padding: 30px">
                            <div class="row text-center">
                                <div class="col-md-6">
                                    <div class="contact-phone">
                                        <i
                                            class="bx bx-phone"
                                            style="font-size: 20px"
                                        ></i>
                                        <p>
                                            <a href="tel:+918041109938"
                                                >+91 80 41109938</a
                                            >
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="contact-email">
                                        <i
                                            class="bx bx-mail-send"
                                            style="font-size: 22px"
                                        ></i>
                                        <p>
                                            <a href="mailto:contact@velabot.com"
                                                >contact@velabot.com</a
                                            >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 contact-us-right-side">
                    <div class="contact-form">
                        <h3>Let's Schedule Your Free Demo</h3>

                        <form id="contactForm" #form="ngForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            class="form-control"
                                            placeholder="Your Name"
                                            [(ngModel)]="userName"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            class="form-control"
                                            placeholder="Your Email"
                                            [(ngModel)]="userEmail"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea
                                            name="message"
                                            class="form-control"
                                            id="message"
                                            cols="30"
                                            rows="6"
                                            placeholder="Please mention the name of your institute and your requirements."
                                            [(ngModel)]="emailMessage"
                                        ></textarea>
                                    </div>
                                </div>
                                <div class="contact-info-content">
                                    <h4
                                        style="color: #13c4a1"
                                        [innerHTML]="emailResponse"
                                    ></h4>
                                    <h4
                                        style="
                                            font-size: 0.875em;
                                            color: #dc3545;
                                        "
                                        [innerHTML]="errorMessage"
                                    ></h4>
                                </div>
                                <div class="mb-2 col-lg-12 col-md-12">
                                    <re-captcha
                                        id="recaptcha"
                                        name="recaptcha"
                                        #recaptcha="ngModel"
                                        siteKey="6LceFjokAAAAAN0uUjgKcDZHjb_wJsowUT64RTz-"
                                        [(ngModel)]="token"
                                        required
                                        [class.is-invalid]="
                                            recaptcha.invalid &&
                                            (recaptcha.dirty ||
                                                recaptcha.touched)
                                        "
                                    ></re-captcha>
                                    <div
                                        *ngIf="
                                            recaptcha.invalid &&
                                            (recaptcha.dirty ||
                                                recaptcha.touched)
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="recaptcha.errors?.['required']"
                                        >
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button
                                        type="submit"
                                        class="default-btn"
                                        (click)="send(form)"
                                    >
                                        <i class="bx bxs-paper-plane"></i
                                        >Submit<span></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
