import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { VelaHomeComponent } from "./components/pages/velahome/velahome.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { NpbridgeAbout } from "./components/pages/npbridge-about/npbridge-about.component";
import { VelaContactComponent } from "./components/pages/vela-contact/vela-contact.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { VelaForInstitute } from "./components/pages/vela-for-institute/vela-for-institute.component";
import { VelaForTeachers } from "./components/pages/vela-for-teachers/vela-for-teachers.component";
import { VelaForLearners } from "./components/pages/vela-for-learners/vela-for-learners.component";
import { ChatbotComponent } from "./components/pages/chatbot/chatbot.component";
import { HelpdeskNewComponent } from "./components/pages/helpdesk-new/helpdesk-new.component";

const routes: Routes = [
    { path: "", component: VelaHomeComponent },
    { path: "about", component: NpbridgeAbout },
    { path: "contact", component: VelaContactComponent },
    { path: "terms", component: TermsConditionsComponent },
    { path: "vela-for-institute", component: VelaForInstitute },
    { path: "vela-for-teachers", component: VelaForTeachers },
    { path: "vela-for-learners", component: VelaForLearners },
    { path: "vela-chatbot", component: ChatbotComponent },
    { path: "vela-helpdesk", component: HelpdeskNewComponent },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            scrollPositionRestoration: "top",
            onSameUrlNavigation: "reload",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
