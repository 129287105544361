<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" style="color: azure">
            <h1>VELA HelpDesk</h1>
            <h4>Learner Support Made Simple With AI!</h4>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title" style="color: #13c4a1"
                        >Quick, Managed and Streamlined Support</span
                    >
                    <h2>
                        Revolutionise your learner support system with HelpDesk
                        integrated with custom AI
                    </h2>
                    <p>
                        VELA HelpDesk is the ultimate solution customised for
                        your institution's needs. With an integrated custom AI,
                        trained on your specific data, VELA Helpdesk becomes
                        your virtual assistant, ready to tackle any learner
                        query about your online learning platform and courses
                        through emails.
                    </p>
                </div>
                <a
                    routerLink="/contact"
                    class="default-btn"
                    style="
                        background-color: #13c4a1;
                        color: #ffffff;
                        margin-top: 2rem;
                    "
                >
                    <i
                        _ngcontent-ilu-c84=""
                        class="bx bxs-hot"
                        style="color: #ffffff"
                    ></i
                    >Schedule Demo<span></span
                ></a>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/helpdesk.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70" style="background-color: #13c4a1">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-conversation"></i>
                    </div>
                    <h3>AI-Powered Ticketing System</h3>
                    <p>
                        Use the power of AI to efficiently manage learner
                        queries. The AI-powered ticketing system automatically
                        understands queries and creates draft responses for
                        support agents to review and send.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-conversation"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-customize"></i>
                    </div>
                    <h3>Custom AI Training</h3>
                    <p>
                        Trained on your institution's specific data, makes VELA
                        highly accurate in providing tailored answers related to
                        your online learning platform and courses.
                    </p>
                    <br />
                    <div class="back-icon">
                        <i class="bx bx-customize"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-slider-alt"></i>
                    </div>
                    <h3>Seamless Knowledge-Base</h3>
                    <p>
                        Effortlessly integrate your institution's knowledge
                        base. This comprehensive resource empowers learners to
                        find instant answers to faqs, minimising the need for
                        repetitive support requests.
                    </p>
                    <br />
                    <div class="back-icon">
                        <i class="bx bx-slider-alt"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-reset"></i>
                    </div>
                    <h3>Automated Responses</h3>
                    <p>
                        Automated responses saves valuable time. Frequently
                        encountered queries receive instant solutions, allowing
                        your support team to focus on more complex tasks and
                        ensuring quicker resolution for learners.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-reset"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-bell"></i>
                    </div>
                    <h3>Collaborative Team Inbox</h3>
                    <p>
                        Experience seamless collaboration among teachers and
                        admin through a shared team inbox. Assigning, tracking,
                        and resolving learner tickets becomes a breeze,
                        enhancing support efficiency and coordination.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-bell"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="bx bx-shape-circle"></i>
                    </div>
                    <h3>Analytics And Insights</h3>
                    <p>
                        Gain valuable data-driven insights with analytics
                        dashboard. Monitor support trends and identify areas for
                        improvement, enabling informed decision-making to
                        optimize learner support experiences.
                    </p>
                    <div class="back-icon">
                        <i class="bx bx-shape-circle"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Testimonials Area -->
<!-- <section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Spacle</span></h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img
                            src="assets/img/author-image/author6.jpg"
                            alt="image"
                        />
                        <h3>Michel John</h3>
                        <span>CEO at Envato</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                        </p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img
                            src="assets/img/author-image/author7.jpg"
                            alt="image"
                        />
                        <h3>Sarah Taylor</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                        </p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-testimonials-item">
                    <div class="client-info">
                        <img
                            src="assets/img/author-image/author8.jpg"
                            alt="image"
                        />
                        <h3>James Andy</h3>
                        <span>CEO at 3S</span>
                    </div>

                    <div class="testimonials-desc">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                        </p>

                        <div class="rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Testimonials Area -->

<app-free-trial></app-free-trial>
