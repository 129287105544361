<!-- Start Free Trial Area -->
<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Accelerating Future Learning</h2>
            <p>
                Experience the power of simple and yet intuitive educational
                experience that both teachers and students love.
            </p>
            <a routerLink="/contact" class="default-btn schedule-demo-btn"
                ><i class="bx bxs-hot"></i> Schedule a Demo <span></span
            ></a>
        </div>
    </div>

    <!-- <div class="shape10"><img src="assets/img/shape/10.png" alt="image" /></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image" /></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image" /></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image" /></div> -->
</section>
<!-- End Free Trial Area -->
