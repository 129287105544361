<!-- Start Error Area -->
<section class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img
                        src="assets/img/npbridge-images/vela-drawing-color.png"
                        width="300"
                        alt="error"
                    />
                    <h4>Uh Oh!</h4>
                    <p>
                        You seem to have travelled a bit in your search for
                        answers.
                        <br />
                        I'm here to assist you with all your questions, but
                        let's first take our route back home!
                    </p>
                    <a routerLink="/" class="default-btn"
                        ><i class="bx bx-rotate-left"></i>Go to Home<span></span
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Error Area -->
