import {
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
    RECAPTCHA_SETTINGS,
    RecaptchaFormsModule,
    RecaptchaModule,
    RecaptchaSettings,
} from "ng-recaptcha";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layout/preloader/preloader.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { VelaHomeComponent } from "./components/pages/velahome/velahome.component";

import { ChattingComponent } from "./components/common/chatting/chatting.component";
import { FreeTrialComponent } from "./components/common/free-trial/free-trial.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { VelaContactComponent } from "./components/pages/vela-contact/vela-contact.component";
import { NpbridgeAbout } from "./components/pages/npbridge-about/npbridge-about.component";
import { environment } from "src/environments/environment.prod";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";

import { GoogleMapsModule } from "@angular/google-maps";
import { VelaForInstitute } from "./components/pages/vela-for-institute/vela-for-institute.component";
import { VelaForTeachers } from "./components/pages/vela-for-teachers/vela-for-teachers.component";
import { VelaForLearners } from "./components/pages/vela-for-learners/vela-for-learners.component";
import { ChatbotComponent } from "./components/pages/chatbot/chatbot.component";
import { HelpdeskNewComponent } from "./components/pages/helpdesk-new/helpdesk-new.component";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HeaderComponent,
        FooterComponent,
        VelaHomeComponent,
        ChattingComponent,
        FreeTrialComponent,
        VelaContactComponent,
        ErrorComponent,
        TermsConditionsComponent,
        NpbridgeAbout,
        VelaForInstitute,
        VelaForTeachers,
        VelaForLearners,
        ChatbotComponent,
        HelpdeskNewComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        NgxGoogleAnalyticsRouterModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        AppRoutingModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
