<!-- Start SaaS Main Banner -->
<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img
                                src="assets/img/saas-shape/arrow.png"
                                class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.6s"
                                alt="arrow"
                            />
                            <img
                                src="assets/img/saas-shape/box1.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="box1"
                            />
                            <img
                                src="assets/img/saas-shape/boy1.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s"
                                alt="boy1"
                            />
                            <img
                                src="assets/img/saas-shape/boy2.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="boy2"
                            />
                            <img
                                src="assets/img/saas-shape/boy3.png"
                                class="wow bounceIn"
                                data-wow-delay="0.6s"
                                alt="boy3"
                            />
                            <img
                                src="assets/img/saas-shape/digital-screen.png"
                                class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.6s"
                                alt="digital-screen"
                            />
                            <img
                                src="assets/img/saas-shape/filter1.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="filter1"
                            />
                            <img
                                src="assets/img/saas-shape/filter2.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="filter2"
                            />
                            <img
                                src="assets/img/saas-shape/filter3.png"
                                class="wow rotateIn"
                                data-wow-delay="0.6s"
                                alt="filter3"
                            />
                            <img
                                src="assets/img/saas-shape/girl1.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="girl1"
                            />
                            <img
                                src="assets/img/saas-shape/girl2.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="girl2"
                            />
                            <img
                                src="assets/img/saas-shape/monitor.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="monitor"
                            />

                            <!-- Main image -->
                            <img
                                src="assets/img/saas-shape/main-image.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="main-image.png"
                            />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4 features-list">
                            <h1>
                                Embrace VELA-Your Perfect Online Learning
                                Partner
                            </h1>

                            <ul
                                style="
                                    padding-top: 2rem;
                                    line-height: 2rem;
                                    font-size: 17px;
                                    padding-bottom: 2rem;
                                "
                            >
                                <li>
                                    Confused by online learning platforms? VELA
                                    simplifies navigation.
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Instant and round-the-clock assistance to
                                    with AI-powered support
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Find resources relevant for you with
                                    integrated knowledge base
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Reduce stress around technical challenges,
                                    with accurate guidance
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Easy-to-use and easy-to-access visual
                                    platform for consistent support
                                </li>
                            </ul>

                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a
                                        routerLink="/contact"
                                        class="default-btn"
                                    >
                                        <i class="bx bxs-hot"></i>
                                        Try VELA
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SaaS Main Banner -->

<!-- Start IS About Area - chatbot -->
<div class="is-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-about-content">
                    <span>Support for learners</span>
                    <h3>
                        An
                        <span style="color: #e10007; margin-bottom: 0">
                            AI-powered Chatbot
                        </span>
                        that acts as your personal online learning assistant
                    </h3>
                    <p>
                        When using platforms like Coursera and Moodle,
                        navigating the various features can be daunting without
                        proper guidance. However, our chatbot is here to assist
                        you by promptly addressing all your platform-related
                        queries.
                    </p>
                    <p>
                        With its help, you can enjoy an uninterrupted online
                        learning experience, making the most of these
                        educational platforms with ease.
                    </p>
                </div>
                <a
                    routerLink="/vela-chatbot"
                    class="default-btn"
                    style="
                        background-color: #13c4a1;
                        padding: 15px 30px;
                        margin-top: 2rem;
                    "
                    >Visit ChatBot<span></span
                ></a>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-about-image">
                    <img
                        src="assets/img/vela-chat.png"
                        alt="image"
                        class="vela-chat-img"
                    />
                    <div class="is-about-shape">
                        <img
                            src="assets/img/it-solution/about/vector.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS About Area -->

<!-- Start IS Case Studies Area - Chatbot -->
<div class="is-case-studies-area ptb-100" style="background-color: #f4f6fc">
    <div class="container">
        <div
            class="section-title-with-large-box d-flex align-items-center justify-content-between"
        >
            <div class="max-width">
                <h2>Why Choose VELA?</h2>
            </div>
        </div>
        <div class="is-case-studies-list-tabs-helpdesk">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="1-tab"
                                data-bs-toggle="tab"
                                href="#vb-one"
                                role="tab"
                                aria-controls="1"
                            >
                                <div class="number">1</div>
                                Quick Query Resolution
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="2-tab"
                                data-bs-toggle="tab"
                                href="#vb-two"
                                role="tab"
                                aria-controls="2"
                            >
                                <div class="number">2</div>
                                Access Anywhere, Anytime
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="3-tab"
                                data-bs-toggle="tab"
                                href="#vb-three"
                                role="tab"
                                aria-controls="3"
                            >
                                <div class="number">3</div>
                                Personalised Assistance
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="4-tab"
                                data-bs-toggle="tab"
                                href="#vb-four"
                                role="tab"
                                aria-controls="4"
                            >
                                <div class="number">4</div>
                                Consistent And Accurate Answers
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="5-tab"
                                data-bs-toggle="tab"
                                href="#vb-five"
                                role="tab"
                                aria-controls="5"
                            >
                                <div class="number">5</div>
                                Course-Specific Guidance
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div
                        class="tab-content"
                        style="margin-top: 3rem"
                        id="myTabContent"
                    >
                        <div
                            class="tab-pane fade show active"
                            id="vb-one"
                            role="tabpanel"
                        >
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-6.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Learners can instantly receive support
                                        for all their queries as they learn
                                        online, reducing the time and effort
                                        required to resolve issues.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-two" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-5.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Round-the-clock support for learners
                                        wherever they are, ensuring that their
                                        queries are answered promptly, even
                                        outside of regular office hours.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="vb-three"
                            role="tabpanel"
                        >
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-4.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Personalised responses are offered by
                                        identifying the learner's query by
                                        leveraging GPT technology to give prompt
                                        and accurate responses.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-four" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-3.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        AI can provide consistent and accurate
                                        answers to learner's queries as it can
                                        generate high-quality responses
                                        consistently through the intelligence
                                        which is customised and designed to
                                        exactly understand what issues the
                                        learner is facing.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-five" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-2.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Access the extensive curated knowledge
                                        base of online learning platforms as
                                        well as the courses being offered by
                                        your institute through the easy-to-use
                                        chat interface.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Case Studies Area -->

<!-- Start SM Services Area -->
<div class="sm-services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Tech support for Moodle and Coursera </span>
            <h2>
                VELA is an expert guide for learners navigating online learning
                platforms
            </h2>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="sm-services-card wow">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-bell"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Account and Notifications</a
                            >
                        </h3>
                        <p>
                            Resolve Account issues like login, password, email
                            verification etc.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card some-left">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-file"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Grades and Assignments
                            </a>
                        </h3>
                        <p>
                            Resolve student issues with grade display,
                            submission, feedback etc.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-bookmark-alt"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Certificates and Verification
                            </a>
                        </h3>
                        <p>
                            Resolve issues with certificates and inform them
                            about the requirements for verification.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sm-services-card-image">
                    <img src="assets/img/about-map.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sm-services-card wow">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-book"></i>
                        </div>
                        <h3>
                            <a routerLink="" style="pointer-events: none"
                                >Course Content
                            </a>
                        </h3>
                        <p>
                            Resolve student questions and issues with content
                            accessibility and material.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card some-right">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-log-in-circle"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Enrollment
                            </a>
                        </h3>
                        <p>
                            Resolve your student issues with the enrollment
                            process on any ed-tech platform or LMS.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-money-withdraw"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Payments and Subscriptions
                            </a>
                        </h3>
                        <p>
                            Resolve queries of your students about course's
                            payment related issues.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sm-services-shape"></div>
</div>
<!-- End SM Services Area -->

<!-- Start App Download Area -->
<section class="app-download-area ptb-100 bg-ffffff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/multi.png" alt="image" width="80%" />
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Multi-Channel Integration</span>
                    <h2>Support is available on your preferred channel</h2>
                    <div class="btn-box">
                        <a
                            href=""
                            class="apple-store-btn"
                            style="pointer-events: none"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
                                alt="image"
                                width="35px"
                                height="40px"
                            />
                            <span>WhatsApp</span>
                        </a>
                        <a
                            href=""
                            class="play-store-btn"
                            style="pointer-events: none"
                        >
                            <img src="assets/img/playstore.png" alt="image" />
                            <span>Google Play</span>
                        </a>
                    </div>
                    <div class="btn-box">
                        <a
                            href=""
                            style="pointer-events: none"
                            class="apple-store-btn"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/768px-Telegram_logo.svg.png?20220101141644"
                                alt="image"
                                width="35px"
                                height="40px"
                            />
                            <span>Telegram</span>
                        </a>
                        <a
                            href=""
                            style="pointer-events: none"
                            class="play-store-btn"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/1024px-Facebook_Messenger_logo_2020.svg.png"
                                alt="image"
                                width="35px"
                                height="40px"
                            />
                            <span>Messenger</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Download Area -->

<!-- <app-free-trial></app-free-trial> -->
