<!-- Start main banner -->
<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img
                                src="assets/img/saas-shape/arrow.png"
                                class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.6s"
                                alt="arrow"
                            />
                            <img
                                src="assets/img/saas-shape/box1.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="box1"
                            />
                            <img
                                src="assets/img/saas-shape/boy1.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s"
                                alt="boy1"
                            />
                            <img
                                src="assets/img/saas-shape/boy2.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="boy2"
                            />
                            <img
                                src="assets/img/saas-shape/boy3.png"
                                class="wow bounceIn"
                                data-wow-delay="0.6s"
                                alt="boy3"
                            />
                            <img
                                src="assets/img/saas-shape/digital-screen.png"
                                class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.6s"
                                alt="digital-screen"
                            />
                            <img
                                src="assets/img/saas-shape/filter1.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="filter1"
                            />
                            <img
                                src="assets/img/saas-shape/filter2.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="filter2"
                            />
                            <img
                                src="assets/img/saas-shape/filter3.png"
                                class="wow rotateIn"
                                data-wow-delay="0.6s"
                                alt="filter3"
                            />
                            <img
                                src="assets/img/saas-shape/girl1.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="girl1"
                            />
                            <img
                                src="assets/img/saas-shape/girl2.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="girl2"
                            />
                            <img
                                src="assets/img/saas-shape/monitor.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="monitor"
                            />

                            <!-- Main image -->
                            <img
                                src="assets/img/saas-shape/main-image.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s"
                                alt="main-image.png"
                            />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4 features-list">
                            <h1>
                                Struggling with learner support overload?
                                Discover how VELA streamlines Institute support
                                processes!
                            </h1>

                            <ul
                                style="
                                    padding-top: 2rem;
                                    line-height: 2rem;
                                    font-size: 17px;
                                    padding-bottom: 2rem;
                                "
                            >
                                <li>
                                    VELA's AI automates support, saving time and
                                    reducing overload
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Personalise assistance for learners using
                                    custom intelligence
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Enhance efficiency of teachers with scalable
                                    technical solutions
                                </li>

                                <li style="margin-top: 0.5rem">
                                    Easy-to-use visual platform and
                                    multi-channel integrations for users
                                </li>
                            </ul>

                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a
                                        routerLink="/contact"
                                        class="default-btn"
                                    >
                                        <i class="bx bxs-hot"></i>
                                        Schedule Demo
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End main banner -->

<!-- Start about helpdesk-->
<div class="is-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-about-content">
                    <span>Support your teachers and admin</span>

                    <h3>
                        Elevate learner support and assist your staff with an
                        Intelligent
                        <span style="color: #e10007; margin-bottom: 0">
                            Digital Helpdesk System
                        </span>
                    </h3>
                    <p>
                        An Intelligent HelpDesk System for institutes can
                        efficiently handle and address all learner queries. By
                        leveraging advanced AI capabilities, it streamlines
                        support processes, enabling staff to respond promptly
                        and accurately to online learner queries.
                    </p>
                    <p>
                        This results in enhanced efficiency and productivity, as
                        the system automates repetitive tasks, frees up staff
                        time, and ensures learners receive timely and accurate
                        assistance.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-about-image">
                    <img src="assets/img/stats.png" alt="image" />
                    <div class="is-about-shape">
                        <img
                            src="assets/img/it-solution/about/vector.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <a
            routerLink="/vela-helpdesk"
            class="default-btn mt-2"
            style="background-color: #13c4a1; padding: 15px 30px"
            >Visit HelpDesk<span></span
        ></a>
    </div>
</div>
<!-- End about helpdesk -->

<!-- Start about chatbot -->
<div class="is-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-about-image">
                    <img
                        src="assets/img/vela-chat.png"
                        alt="image"
                        class="vela-chat-img"
                    />
                    <div class="is-about-shape">
                        <img
                            src="assets/img/it-solution/about/vector.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-about-content">
                    <span>Support your learners</span>
                    <h3>
                        <span style="color: #e10007; margin-bottom: 0">
                            Generative AI-powered Chatbot
                        </span>
                        that instantly provides personalised assistance to your
                        learners' online learning needs
                    </h3>
                    <p>
                        A chatbot designed for navigating edtech platforms can
                        efficiently address all learner queries, enabling
                        institutes to avoid time-consuming teacher and student
                        training.
                    </p>
                    <p>
                        By leveraging custom intelligence, the chatbot offers
                        instant and accurate responses to help users navigate
                        the online learning platform with ease, saving valuable
                        time and resources, ensuring a seamless learning
                        experience.
                    </p>
                </div>
                <a
                    routerLink="/vela-chatbot"
                    class="default-btn"
                    style="
                        background-color: #13c4a1;
                        padding: 15px 30px;
                        margin-top: 2rem;
                    "
                    >Visit ChatBot<span></span
                ></a>
            </div>
        </div>
    </div>
</div>
<!-- End IS About Area -->

<!-- Start IS Case Studies Area - Chatbot -->
<div class="is-case-studies-area ptb-100" style="background-color: #f4f6fc">
    <div class="container">
        <div
            class="section-title-with-large-box d-flex align-items-center justify-content-between"
        >
            <div class="max-width">
                <h2>Why Choose VELA?</h2>
            </div>
        </div>
        <div class="is-case-studies-list-tabs-helpdesk">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="1-tab"
                                data-bs-toggle="tab"
                                href="#vb-one"
                                role="tab"
                                aria-controls="1"
                            >
                                <div class="number">1</div>
                                Simplified Query Management
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="2-tab"
                                data-bs-toggle="tab"
                                href="#vb-two"
                                role="tab"
                                aria-controls="2"
                            >
                                <div class="number">2</div>
                                Systematic Support Experience
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="3-tab"
                                data-bs-toggle="tab"
                                href="#vb-three"
                                role="tab"
                                aria-controls="3"
                            >
                                <div class="number">3</div>
                                Analytics and Insights
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="4-tab"
                                data-bs-toggle="tab"
                                href="#vb-four"
                                role="tab"
                                aria-controls="4"
                            >
                                <div class="number">4</div>
                                Data Backup and Restoration
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="5-tab"
                                data-bs-toggle="tab"
                                href="#vb-five"
                                role="tab"
                                aria-controls="5"
                            >
                                <div class="number">5</div>
                                Scalable and Customisable
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div
                        class="tab-content"
                        style="margin-top: 3rem"
                        id="myTabContent"
                    >
                        <div
                            class="tab-pane fade show active"
                            id="vb-one"
                            role="tabpanel"
                        >
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-6.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Easily manage and respond accurately to
                                        learner queries and also get an overview
                                        of all the queries raised by your
                                        learners to facilitate informed decision
                                        making.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-two" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-5.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        A defined process that ensures you never
                                        again miss a learner's question and that
                                        they receive answers on time.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="vb-three"
                            role="tabpanel"
                        >
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-4.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        VELA provides valuable data and insights
                                        into learner queries, allowing
                                        institutes to identify common issues and
                                        areas for improvement.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-four" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-3.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Have all your data backed up
                                        automatically, so you can rest assured
                                        that no data is lost.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-five" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-2.png"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Access the extensive curated knowledge
                                        base of online learning platforms
                                        customised for your institute that can
                                        address all of your learners'
                                        Platform-related as well as
                                        course-specific questions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="vb-six" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/chatbot-1.png"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Case Studies Area -->

<!-- Start SM Services Area -->
<div class="sm-services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Get support for Moodle and Coursera </span>
            <h2>
                VELA is an expert guide for teachers and learners navigating
                online learning platforms
            </h2>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="sm-services-card wow">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-bell"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Account and Notifications</a
                            >
                        </h3>
                        <p>
                            Resolve Account issues like login, password, email
                            verification etc.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card some-left">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-file"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Grades and Assignments
                            </a>
                        </h3>
                        <p>
                            Resolve learner issues with grade display,
                            submission, feedback etc.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-bookmark-alt"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Certificates and Verification
                            </a>
                        </h3>
                        <p>
                            Resolve issues with certificates and inform them
                            about the requirements for verification.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sm-services-card-image">
                    <img src="assets/img/about-map.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sm-services-card wow">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-book"></i>
                        </div>
                        <h3>
                            <a routerLink="" style="pointer-events: none"
                                >Course Content
                            </a>
                        </h3>
                        <p>
                            Resolve learner questions and issues with content
                            accessibility and material.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card some-right">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-log-in-circle"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Enrollment
                            </a>
                        </h3>
                        <p>
                            Resolve your learner issues with the enrollment
                            process on any ed-tech platform or LMS.
                        </p>
                    </div>
                </div>
                <div class="sm-services-card">
                    <div class="content">
                        <div class="icon">
                            <i class="bx bx-money-withdraw"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-services"
                                style="pointer-events: none"
                                >Payments and Subscriptions
                            </a>
                        </h3>
                        <p>
                            Resolve queries of your learners about course's
                            payment related issues.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sm-services-shape"></div>
</div>
<!-- End SM Services Area -->

<!-- Start App Download Area -->
<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/multi.png" alt="image" width="80%" />
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Multi-Channel Integration</span>
                    <h2>Support is available on your preferred channel</h2>
                    <div class="btn-box">
                        <a
                            href=""
                            class="apple-store-btn"
                            style="pointer-events: none"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
                                alt="image"
                                width="35px"
                                height="40px"
                            />
                            <span>WhatsApp</span>
                        </a>
                        <a
                            href=""
                            class="play-store-btn"
                            style="pointer-events: none"
                        >
                            <img src="assets/img/playstore.png" alt="image" />
                            <span>Google Play</span>
                        </a>
                    </div>
                    <div class="btn-box">
                        <a
                            href=""
                            style="pointer-events: none"
                            class="apple-store-btn"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/768px-Telegram_logo.svg.png?20220101141644"
                                alt="image"
                                width="35px"
                                height="40px"
                            />
                            <span>Telegram</span>
                        </a>
                        <a
                            href=""
                            style="pointer-events: none"
                            class="play-store-btn"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/1024px-Facebook_Messenger_logo_2020.svg.png"
                                alt="image"
                                width="35px"
                                height="40px"
                            />
                            <span>Messenger</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Download Area -->

<!-- <app-free-trial></app-free-trial> -->
