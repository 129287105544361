import { Component, OnInit } from "@angular/core";
import axios from "axios";
import { environment } from "src/environments/environment.prod";
import { NgForm } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "app-contact",
    templateUrl: "./vela-contact.component.html",
    styleUrls: ["./vela-contact.component.scss"],
})
export class VelaContactComponent implements OnInit {
    token: string | undefined;
    apiLoaded: Observable<boolean>;
    center: google.maps.LatLngLiteral = {
        lat: 12.916117892553553,
        lng: 77.65238181070896,
    };
    zoom = 17;
    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
        title: "Npbridge Solutions",
        position: this.center,
        clickable: true,
    };

    openMaps() {
        // Open npbridge on google maps
        window.open("https://goo.gl/maps/5bRWJWde5nZnrk5s8");
    }

    constructor(httpClient: HttpClient) {
        this.token = undefined;

        this.apiLoaded = httpClient
            .jsonp(
                `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApi}`,
                "callback"
            )
            .pipe(
                map(() => true),
                catchError(() => of(false))
            );
    }

    public send(form: NgForm): void {
        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }
            return;
        }
        this.submitForm();
    }

    userName: string = "";
    userEmail: string = "";
    emailMessage: string = "";
    emailResponse = "";
    errorMessage = "";

    validations() {
        var validMessage = {
            status: true,
            message: "",
        };
        if (!this.userName) {
            validMessage.status = false;
            validMessage.message = "Name cannot be empty.";
            return validMessage;
        }
        if (!this.userEmail) {
            validMessage.status = false;
            validMessage.message = "Email cannot be empty.";
            return validMessage;
        }

        if (!this.emailMessage) {
            validMessage.status = false;
            validMessage.message = "Message cannot be empty.";
            return validMessage;
        }
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.userEmail.match(mailformat)) {
            validMessage.status = false;
            validMessage.message = "Please enter a valid email address.";
            return validMessage;
        }

        return validMessage;
    }

    submitForm() {
        this.errorMessage = "";
        this.emailResponse = "";
        const validated = this.validations();
        if (validated.status) {
            const postData = {
                sendToEmail: environment.sendToEmail,
                userName: this.userName,
                userEmail: this.userEmail,
                subject: `CONTACT FORM`,
                message: this.emailMessage,
            };
            axios
                .post(`${environment.backendApi}/contact-submit`, postData)
                .then((res) => {
                    this.userEmail = "";
                    this.userName = "";
                    this.emailMessage = "";
                    if ((res.status = 200))
                        this.emailResponse = "Message sent successfully!";
                    else
                        this.errorMessage =
                            "Message could not be sent right now. Please send an email on the below email id.";
                })
                .catch((err) => {
                    this.errorMessage =
                        "Message could not be sent right now. Please send an email on the below email id.";
                });
        } else {
            this.errorMessage = validated.message;
        }
    }

    ngOnInit() {}
}
