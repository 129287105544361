import { Component, OnInit } from "@angular/core";

@Component({
    selector: "vela-for-learners",
    templateUrl: "./vela-for-learners.component.html",
    styleUrls: ["./vela-for-learners.component.scss"],
})
export class VelaForLearners implements OnInit {
    constructor() {}

    ngOnInit() {}
}
