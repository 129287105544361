<!-- Start Digital Agency Banner -->
<section class="digital-agency-banner">
    <div class="container">
        <div class="digital-agency-banner-content">
            <h1 class="wow animate__animated animate__fadeInUp">
                VELA: A Product <br />
                Of
                <span> NPBridge</span>
            </h1>
            <p class="wow animate__animated animate__fadeInUp">
                VELA, a product of NPBridge , simplifies the adoption of online
                learning platforms like Coursera and Moodle, which can be
                challenging for teachers and learners. By improving
                accessibility, VELA envisions an easier transition to new
                digital tools for teaching and learning.
            </p>
        </div>
    </div>
</section>
<!-- End Digital Agency Banner -->

<!-- Start IS Features Area -->
<div class="is-features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title pt-50">
            <h1>Towards an Inclusive and Accessible Future of Learning</h1>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number">
                        <span>SDG 4</span>
                    </div>
                    <h3>
                        <a
                            routerLink="/single-services"
                            style="pointer-events: none; cursor: default"
                            >Mitigating Limited digital literacy and exposure
                        </a>
                    </h3>
                    <p>
                        VELA's user-friendly solutions address the digital skill
                        gap by making its extensive knowledge base easily
                        accessible to both students and teachers, who may find
                        the usage of digital platforms a novel experience and a
                        significant barrier to effective teaching and learning.
                        <br />
                        <br />
                    </p>
                    <div class="icon">
                        <i
                            style="
                                background-image: url('assets/img/second-icon.png');
                                background-size: 70%;
                                background-repeat: no-repeat;
                                background-position-x: 12px;
                                background-position-y: 12px;
                                background-color: #13c4a1;
                                color: white;
                                height: 80px;
                                width: 80px;
                                display: block;
                            "
                        ></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number">
                        <span>SDG 9</span>
                    </div>
                    <h3>
                        <a
                            routerLink="/single-services"
                            style="pointer-events: none; cursor: default"
                            >Ease Complex Digital Platform Functionalities
                        </a>
                    </h3>
                    <p>
                        Online platform issues, such as logging in or submitting
                        assignments, can be challenging for students, while
                        teachers may find it difficult to assist multiple
                        students simultaneously. VELA's intelligence functions
                        as a virtual teaching assistant, helping to address
                        students' difficulties during online learning.
                    </p>

                    <div class="icon">
                        <i
                            style="
                                background-image: url('assets/img/first-icon.png');
                                background-size: 80%;
                                background-repeat: no-repeat;
                                background-position-x: 8px;
                                background-position-y: 6px;
                                background-color: #13c4a1;
                                color: white;
                                height: 80px;
                                width: 80px;
                                display: block;
                            "
                        ></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number">
                        <span>SDG 10</span>
                    </div>
                    <h3>
                        <a
                            routerLink="/single-services"
                            style="pointer-events: none; cursor: default"
                            >Overcome Unfamiliar Learning Environment
                        </a>
                    </h3>
                    <p>
                        VELA provides students with the support they need to
                        navigate the online learning environment, offering
                        teachers the ability to provide real-time guidance and
                        assistance to students, even when they are learning
                        independently.
                        <br />
                        <br />
                        <br />
                    </p>
                    <div class="icon">
                        <i
                            style="
                                background-image: url('assets/img/third-icon.png');
                                background-size: 80%;
                                background-repeat: no-repeat;
                                background-position-x: 8px;
                                background-position-y: 6px;
                                background-color: #13c4a1;
                                color: white;
                                height: 80px;
                                width: 80px;
                                display: block;
                            "
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Features Area -->

<!-- Start IS Expertise Area -->
<div class="is-expertise-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-expertise-image">
                    <img
                        src="assets/img/about-us-1.png"
                        style="
                            border-top-right-radius: 10%;
                            border-bottom-left-radius: 10%;
                        "
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-expertise-content">
                    <span class="sub-title">About NPBridge</span>
                    <h3>Technology That Means Impact</h3>
                    <p>
                        NPBridge is a technology company committed to creating a
                        positive impact in the social sector. Our mission is to
                        empower organisations with the tools they need to
                        achieve their goals and make a meaningful difference in
                        the world.
                    </p>
                    <h3>Empowering Education Through Intelligence</h3>
                    <p>
                        We have developed VELA, an intelligent solution designed
                        to enhance the online learning experience for students
                        and teachers. With the power of AI, we are helping
                        teachers provide their students with the support and
                        guidance they need to succeed.
                    </p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="expertise-funfacts">
                                <h2>
                                    <span
                                        class="odometer"
                                        data-count="15"
                                    ></span>
                                    <span class="sign-icon">+</span>
                                </h2>
                                <p>Projects Launched</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="expertise-funfacts bg-DEEEFA">
                                <h2>
                                    <span class="odometer" data-count="6"
                                        >L</span
                                    >
                                    <span class="sign-icon">L+</span>
                                </h2>
                                <p>Users Benefitted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Expertise Area -->

<!-- Start Team Area -->
<section class="team-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Our Team</span>
            <h2>We Help to Acheive Your Goals</h2>
        </div>
        <div class="row">
            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
            >
                <div class="single-team-member">
                    <div class="image">
                        <img
                            src="https://www.npbridge.com/assets/img/kushal.jpg"
                            alt="team-image"
                        />

                        <ul class="social-link">
                            <li></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Kushal Sacheti</h3>
                        <span>Founder</span>
                        <a
                            href="https://www.linkedin.com/in/kushal-sacheti-5986084/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".4s"
            >
                <div class="single-team-member">
                    <div class="image">
                        <img
                            src="https://www.npbridge.com/assets/img/ashish.png"
                            alt="team-image"
                        />
                    </div>

                    <div class="content">
                        <h3>Ashish Agrawal</h3>
                        <span>Co-Founder & CEO</span>
                        <a
                            href=" https://www.linkedin.com/in/ashish371/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <div class="single-team-member">
                    <div class="image">
                        <img
                            src="https://www.npbridge.com/assets/img/tiwari.jpg"
                            alt="team-image"
                        />
                    </div>

                    <div class="content">
                        <h3>Sachchidanand Tiwari</h3>
                        <span>Member Technical</span>
                        <a
                            href="https://www.linkedin.com/in/sachchidanandtiwari/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
            >
                <div class="single-team-member">
                    <div class="image">
                        <img
                            src="https://www.npbridge.com/assets/img/yogita.jpg"
                            alt="team-image"
                        />
                    </div>

                    <div class="content">
                        <h3>Yogita Suyal</h3>
                        <span>Member - Domain & Outreach</span>
                        <a
                            href="https://www.linkedin.com/in/yogita-suyal-27514291/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
            >
                <div class="single-team-member">
                    <div class="image">
                        <img
                            src="https://www.npbridge.com/assets/img/raya.jpg"
                            alt="team-image"
                        />
                    </div>

                    <div class="content">
                        <h3>Raya Das</h3>
                        <span>Member - Domain & Research</span>
                        <a
                            href="https://www.linkedin.com/in/raya-das-010546143/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".4s"
            >
                <div class="single-team-member">
                    <div class="image">
                        <img
                            src="https://www.npbridge.com/assets/img/jahnavi.jpg"
                            alt="team-image"
                        />
                    </div>

                    <div class="content">
                        <h3>Jahnavi Raj</h3>
                        <span>Member Technical</span>
                        <a
                            href="https://www.linkedin.com/in/jahnavi-raj-ab5809194/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
            >
                <div class="single-team-member">
                    <div class="image" style="height: 306px">
                        <img
                            width="306"
                            height="306"
                            src="https://www.npbridge.com/assets/img/pooja.jpg"
                            alt="team-image"
                        />
                    </div>

                    <div class="content">
                        <h3>Pooja Patil</h3>
                        <span>Member Technical</span>
                        <a
                            href="https://www.linkedin.com/in/pooja-patil-34a821171/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>
            <div
                class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <div class="single-team-member">
                    <div class="image" style="height: 306px">
                        <img
                            src="assets/img/shivani.jpg"
                            alt="team-image"
                            width="306px"
                            height="296px"
                        />
                    </div>

                    <div class="content">
                        <h3>Shivani Tadinada</h3>
                        <span>Intern Technical</span>
                        <a
                            href="https://www.linkedin.com/in/shivani-tadinada-337a3b22b/"
                            class="d-block"
                            target="_blank"
                            ><i class="bx bxl-linkedin"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start IS Analysis Area - Partnership form -->
<div class="is-analysis-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-analysis-image">
                    <img src="assets/img/vela-about-1.png" alt="image" />
                    <div class="analysis-vector-shape">
                        <img
                            src="assets/img/it-solution/team/vector.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-analysis-content">
                    <h3>Partner with VELA for impact!</h3>
                    <form id="contactForm" #form="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        required
                                        placeholder="Your Name"
                                        [(ngModel)]="userName"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        required
                                        placeholder="Your Email"
                                        [(ngModel)]="userEmail"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Your Message</label>
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        id="message"
                                        cols="30"
                                        rows="6"
                                        required
                                        placeholder="Your Message"
                                        [(ngModel)]="emailMessage"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="contact-info-content">
                                <h4
                                    style="color: #13c4a1"
                                    [innerHTML]="emailResponse"
                                ></h4>
                                <h4
                                    style="font-size: 0.875em; color: #dc3545"
                                    [innerHTML]="errorMessage"
                                ></h4>
                            </div>
                            <div class="mb-2 col-lg-12 col-md-12">
                                <re-captcha
                                    id="recaptcha"
                                    name="recaptcha"
                                    #recaptcha="ngModel"
                                    siteKey="6LceFjokAAAAAN0uUjgKcDZHjb_wJsowUT64RTz-"
                                    [(ngModel)]="token"
                                    required
                                    [class.is-invalid]="
                                        recaptcha.invalid &&
                                        (recaptcha.dirty || recaptcha.touched)
                                    "
                                ></re-captcha>
                                <div
                                    *ngIf="
                                        recaptcha.invalid &&
                                        (recaptcha.dirty || recaptcha.touched)
                                    "
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="recaptcha.errors?.['required']">
                                        This field is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn"
                                    (click)="send(form)"
                                >
                                    Become a partner<span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Analysis Area -->

<app-free-trial></app-free-trial>
