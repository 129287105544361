import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-helpdesk-new",
    templateUrl: "./helpdesk-new.component.html",
    styleUrls: ["./helpdesk-new.component.scss"],
})
export class HelpdeskNewComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
