import { Component, OnInit } from "@angular/core";

@Component({
    selector: "vela-for-institute",
    templateUrl: "./vela-for-institute.component.html",
    styleUrls: ["./vela-for-institute.component.scss"],
})
export class VelaForInstitute implements OnInit {
    constructor() {}

    ngOnInit() {}
}
