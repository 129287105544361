import { Component, OnInit } from "@angular/core";

@Component({
    selector: "vela-for-teachers",
    templateUrl: "./vela-for-teachers.component.html",
    styleUrls: ["./vela-for-teachers.component.scss"],
})
export class VelaForTeachers implements OnInit {
    constructor() {}

    ngOnInit() {}
}
