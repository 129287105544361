<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{
        'd-none':
            router.url === '/home-nine' ||
            router.url === '/home-ten' ||
            router.url === '/home-eleven'
    }"
>
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget" style="margin-top: -10px">
                    <div class="logo" style="overflow: auto">
                        <a routerLink="/" style="color: #ffffff">
                            <img
                                src="assets/img/vela_logo_60x60.png"
                                alt="logo"
                            />
                            <span
                                style="
                                    font-size: 1.2rem;
                                    font-weight: 510;
                                    letter-spacing: 0.8px;
                                "
                                >VELA</span
                            >
                        </a>
                    </div>
                    <p>
                        VELA - powered by AI, is exclusively designed to assist
                        teachers and students. Whether you're adopting a new
                        learning platform or trying to make the online learning
                        processes more efficient, get support every step of the
                        way with VELA.
                    </p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/about">About Us</a></li>

                        <!-- <li><a routerLink="/">Our Story</a></li> -->
                        <li>
                            <a target="_blank" href="https://blog.npbridge.com/"
                                >Latest News</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/terms">Terms & Conditions</a></li>
                        <!-- <li><a routerLink="/">Community</a></li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            Location:
                            <a
                                href="https://goo.gl/maps/WkDg3ZHA8sZ2Z58x8"
                                target="_blank"
                                >HSR Layout, Bangalore, India</a
                            >
                        </li>
                        <li>
                            Email:
                            <a href="mailto:contact@velabot.com"
                                >contact@velabot.com</a
                            >
                        </li>
                        <li>
                            Phone:
                            <a href="tel:+918041109938">+91 804 110 9938</a>
                        </li>
                    </ul>
                    <ul class="social">
                        <!-- <li><a routerLink="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a routerLink="#" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                        <li>
                            <a
                                href="https://www.linkedin.com/company/81356764/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <!-- <li><a routerLink="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>
                Copyright @2023 VELA. A Product of
                <a href="http://npbridge.com/" target="_blank">NPBridge</a>
            </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class="bx bx-chevron-up"></i></div>
